/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '莫忘初心',
  // 网站标题
  siteTitle: '莫忘初心-专注于技术分享的博客平台！',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请耐心等待',
  // 网站描述
  siteDescription:
    '莫忘初心是一个专注于技术分享的博客平台！并且还会分享各种实用工具、实用技巧！',
  // 网站关键字
  siteKeywords:
    '莫忘初心,博客,莫忘初心博客,莫忘初心社区,莫忘初心技术社区,莫忘初心,IT社区,IT社区,技术社区,Java技术分享,Spring教程,开发者社区',
  // 备案号
  recordTitle: '鲁ICP备20010645号'
}
